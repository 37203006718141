* {
  direction: rtl;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

a {
  color: #fff;
  text-decoration: underline;
}

body {
  background-color: #282c34;
}

.details {
  font-size: 3.5vh;
  color: #fff;
  text-align: center;
  line-height: 4vh;
  margin-top: 3.5vh;
}

.contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 1vw;
  margin-top: 2vh;
}

@media screen and (max-width: 660px) {
  .contact {
    flex-direction: column;
    row-gap: 1.5vh;
  }
  .contact > .spacer {
    display: none;
  }
}
